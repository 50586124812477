import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home/home2.JPG";
import Particle from "../Particle";
import Home2 from "./Home2";
import home from '../../Assets/home/home.jpg'
// import { motion } from "framer-motion";
import Button from 'react-bootstrap/Button';
import { FaTiktok } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";


function Home() {
  return (

    <section>
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row>
            <img
              src={home}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "100%" }}
            />
          </Row>
          <Row style={{ textAlign: "center" }}>

            <h1 className="heading-name" style={{ color: "grey" }}>
              <strong className="rowdies-bold" style={{ fontSize: 100 }}>Directed By Ton</strong>
            </h1>

            <div style={{ padding: 20, fontSize: 50 }}>
              <h3>
                I work to make
                Your dreams come
                true.
              </h3>
            </div>

          </Row>
        </Container>
      </Container>

      <Button variant="secondary" style={{
        height: "70px", width: "200px",
        paddingTop: "10px", fontSize: "30px", color: "black", boxShadow: "0 4px 5px 3px rgba(160, 160, 171, 0.459)"
      }}
        href="https://api.whatsapp.com/send?phone=%2B855975336533&context=ARCsFDL8CGXCtV0uzROkVpi1yWOCS51LoWvcB8ZtxaGtjEdJJ8G0wtr7HdOsxXFCGxhdvh4HUzc9v-gADptJEJv_Uv5hm71i16YD4Ok7x1Sd4NNftHk-9EM8JAEYwmkMktcDKzP2XvV0w28Js2w3gb2p9g">
        Booking
      </Button>

      <Home2 />
    </section >
  );
}

export default Home;
