import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ListGroup from 'react-bootstrap/ListGroup';
import ScrollTop from '../../components/ScrollTop'

import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillYoutube
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

function About() {
  return (
    <Container fluid className="about-section">
      {/* <Particle /> */}
      <Container fluid="md" className="about-skill">

        <Row className="pb-5">
          <Col>
            <h1 className="rowdies-bold" style={{ color: "grey", textAlign: "center" }}>Introduce myself</h1><br />
            Hello, my name is Ton. I have many skills that can help make your dreams come true with my experience of over a decade, including techniques, standard rules, and unlimited speed, ensuring that every project is created. Built with clear reliability, quality, standards and high creativity.
          </Col>
        </Row>

        <Row>
          <Col md={12} className="home-about-social">
            <p>
              Connect
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://youtube.com/@directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillYoutube />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.tiktok.com/@directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTiktok />
                </a>
              </li>


            </ul>

          </Col>
        </Row>

        <Row>

          <Col style={{ height: 500 }}>
            {/* <h1 className="rowdies-bold" style={{ color: "grey", textAlign: "center" }}>My Skills</h1>

            <ul className="ul-skill">
              <li>Produce, direct, record, and edit creative content that reflects creativity and quality, including social media, short stories, storytelling, branding, long forms, news conten</li>
              <li>Collaborate with marketing teams to create creative ideas that align with marketing and audio strategies.</li>
              <li>Runs a variety of equipment, including drone cameras and flash to produce high-quality</li>
              <li>Edit and assemble the latest visual and audio material, ensuring the consistency and harmony of the story.</li>
              <li>Manage post-production processes, including coloring, sound editing, and graphic integration.</li>
              <li>Get the latest news with the latest technical trends and technologies in production and editing.</li>
              <li>Work on multiple projects simultaneously, adhering to strict deadlines while maintaining both quantity and quality.</li>
        
              <li>Sourcing talents, locations and prop</li>
              <li>Manage calendars and scheduling</li>
              <li>Maintain budgets and track billing</li>
              <li>Oversee the quality of the finished product</li>
              <li>Assist the creative department with projects execution</li>
              <li>Flexible and be able to adapt with urgency</li>
            </ul> */}

          </Col>
        </Row>



      </Container >
    </Container >
  );
}

export default About;